import React from 'react';
import './Main.css';

const Main = () => {
    return (
        <main>
            <img className="squareimagesmall" src="/img/main4.jpg" alt="squareimage3" />
            <img className="squareimagebig" src="/img/main5.jpg" alt="squareimage1" />
            <div className="square">
                <h1 className="squareitem">Learn <u> your dream language</u> with exceptional tutors at unbeatable rates and deals!</h1><br></br>
                <p className='squareitem'>Find your rhythm and start learning now! <br></br>Exciting and personalized language courses from your own private tutor to take you to bilingual status fast!</p>
                <div className="squareitem-button">
                    <a className="squareitem-button" href="/tutors">START NOW!</a>
                </div>
            </div>
            <img className="squareimagebig" src="/img/main6.jpg" alt="squareimage2" />
            <img className="squareimagesmall" src="/img/main7.jpg" alt="squareimage4" />
        </main>
    );
};

export default Main;
