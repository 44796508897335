import React, { useState, useEffect } from 'react';
import './Membership.css';

const getAPIUrl = () => {
    // if (process.env.NODE_ENV === 'development') {
    //     return 'http://localhost:3001';
    // } else {
        return 'https://learnlantern.com';
    // }
}

const MembershipOptions = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [tutorUID, setTutorUID] = useState(null);
    const [studentUID, setStudentUID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [studentEmail, setStudentEmail] = useState(null);

    useEffect(() => {
        const storedTutorUID = localStorage.getItem('tutorUID');
        const storedStudentUID = localStorage.getItem('studentUID');
        const storedEmail = localStorage.getItem('studentEmail');

        if (!storedTutorUID || !storedStudentUID || !storedEmail) {
            setError('Missing user credentials. Please log in again.');
            return;
        }

        setTutorUID(storedTutorUID);
        setStudentUID(storedStudentUID);
        setStudentEmail(storedEmail);
    }, []);

    const handleSelect = (option) => {
        setError(null);
        setSelectedOption(option);
    };

    const getCreditsToPurchase = (option) => {
        const credits = {
            '4 Credits/Month': 4,
            '8 Credits/Month': 8,
            '12 Credits/Month': 12,
            '16 Credits/Month': 16
        };
        return credits[option] || 0;
    };

    const handlePurchaseCredits = async () => {
        setLoading(true);
        setError(null);

        if (!tutorUID || !studentUID) {
            setError("Please log in to purchase credits");
            setLoading(false);
            return;
        }

        const creditsToPurchase = getCreditsToPurchase(selectedOption);

        try {
            const response = await fetch(
                `${getAPIUrl()}/membership`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        tutorId: tutorUID,
                        creditsToPurchase,
                        pricePerCredit: 10,
                        studentUID: studentUID,
                        email: studentEmail
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to create checkout session');
            }

            const session = await response.json();

            if (session.url) {
                window.location.href = session.url;
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            console.error('Checkout error:', error);
            setError(error.message || 'Failed to initiate checkout. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (error) {
        return (
            <div className="error-message">
                {error}
                <button onClick={() => setError(null)}>Dismiss</button>
            </div>
        );
    }

    return (
        <div className="membership-container">
            <div className="title-subtitle">
                <h2 className="membership-header">Start Your Language Learning Journey Today!</h2>
                <p className="membership-description">Select the membership that best fits your goals:</p>
            </div>

            <div className="membership-options-container">
                <div className="membership-box">
                    {['4 Credits/Month', '8 Credits/Month', '12 Credits/Month', '16 Credits/Month'].map((option) => (
                        <button
                            key={option}
                            className={`membership-button ${selectedOption === option ? 'selected' : ''}`}
                            onClick={() => handleSelect(option)}
                            disabled={loading}
                        >
                            <h2>{option.split(' ')[0]} Credits</h2>
                            <img
                                src={`/img/${option.split(' ')[0]}credits.png`}
                                alt="credit icon"
                                className="membership-option-icon"
                            />
                            <h2 className='membership-option-header'>
                                {`${parseInt(option) / 4} Classes / Week`}
                            </h2>
                            <p className="membership-option-description">
                                {option === '4 Credits/Month' && 'Perfect for learners with a solid foundation. This plan helps you practice and refine your language skills while learning new nuances without the pressure of an intense schedule.'}
                                {option === '8 Credits/Month' && 'Ideal for learners committed to steady improvement. With regular classes and additional practice, this plan helps you progress through language levels at a comfortable pace.'}
                                {option === '12 Credits/Month' && 'Designed for motivated learners who want to accelerate their language learning. This plan offers more frequent sessions, helping you achieve fluency faster.'}
                                {option === '16 Credits/Month' && 'An intensive learning plan for passionate learners. This plan is for those who want to immerse themselves in the language and culture, making it a part of their daily lives.'}
                            </p>
                        </button>
                    ))}
                </div>
            </div>

            <div className='membership-divider'>
                <button
                    className="membership-confirm-button"
                    onClick={handlePurchaseCredits}
                    disabled={!selectedOption || loading}
                >
                    {loading ? 'Processing...' : `Purchase ${selectedOption}`}
                </button>
            </div>
        </div>
    );
};

export default MembershipOptions;